import React, { useEffect } from 'react';

import './SignIn.scss';
import { VexaLogo } from '../VexaLogo';
import { CredentialResponse, GoogleLogin, TokenResponse, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { MessageType } from '../../../services/message-listener.service';
import { sendMessage } from '../../helpers/in-content-messaging.helper';
import googleMeetLogo from "../../../assets/images/svg/google-logo.svg";
import meetPreviewImg from "../../../assets/images/svg/meet-preview.svg";

import whiteStarIcon from "../../../assets/images/svg/white-star.svg";
import { useApi } from '../../contexts/ApiContext';
import axios from 'axios';

export interface SignInProps {
  prop?: string;
}

export function SignIn({ }: SignInProps) {
  const { post, setUser } = useApi();

  const signInUser = async (credentials: CredentialResponse) => {
    sendMessage(MessageType.SIGNIN_STARTED);

    post('auth/google', { token: credentials.credential })
      .then(res => {
        setUser(null);
        const authURL = new URL(res.link);
        const pageUrl = new URL(window.location.href);
        const params: any = {};
        authURL.searchParams.forEach((value, key) => {
          params[key] = value;
          if (key.startsWith('__vexa')) {
            localStorage.setItem(key, value);
          }
        });
        const vexaToken = authURL.searchParams.get('__vexa_token');
        if (pageUrl.searchParams.has('caller') && pageUrl.searchParams.get('caller') === 'vexa-ext') {
          try {
            window.close();
          } catch (e) {
            console.error(e);
          }
        }
        window.location.href = res.link + `&action=configure&redirect=` + encodeURI(`${process.env.REACT_APP_BASE_URL}?token=${vexaToken}`);
      }, error => {
        console.error(error);
        sendMessage(MessageType.SIGNIN_ERROR);
        toast('Login failed', { type: 'error' });
      });

  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => signInUser(tokenResponse as any),
    onError: () => {
      toast('Login Failed', { type: 'error' })
    }
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const [, action] = Array.from(queryParams).find(q => q[0] === 'action') || [];

    switch (action) {
      case 'configure':
        queryParams.forEach((value, key) => key.startsWith('__vexa') && localStorage.setItem(key, value));

        if (queryParams.get('redirect')) {
          window.location.href = queryParams.get('redirect');
        }
        break;
    }

    // setUser(null);
    // localStorage.clear();
    // googleLogout();
  }, []);

  return (
    <div className='SignIn flex h-screen w-screen overflow-hidden bg-[#0c111d]'>
      <div className="w-full flex items-center justify-center flex-col lg:w-2/5">
        <div className="flex flex-col max-w-[400px] m-auto p-5">
          <VexaLogo className='mb-20' />
          <h4 className='font-semibold text-4xl mb-3 text-[#F5F5F6]'>Sign in</h4>
          <p className='text-base font-normal mb-8 text-[#94969C]'>Please enter your details to keep all your records saved</p>
          <GoogleLogin
            size='large'
            width={300}
            onSuccess={credentialResponse => {
              signInUser(credentialResponse);
            }}
            onError={() => {
              toast('Login Failed', { type: 'error' });
            }}
            useOneTap
            auto_select
          />

        </div>

        <div className="p-8 w-full">
          <p className='text-sm text-[#94969C] font-normal'>&copy; Vexa {new Date().getFullYear()}</p>
        </div>

      </div>
      <div className="w-3/5 hidden lg:flex flex-col p-20 bg-[#1F242F] text-[#F5F5F6]">
        <h3 className='font-medium text-3xl'>
          With Vexa you can generate summaries and to-do lists, create engaging content, and analyze your meetings.
        </h3>

        <div className='w-full rounded-lg overflow-hidden mx-auto mt-[60px]'>
          <iframe className='' width='100%' height="504px"
            allowFullScreen
            src="https://www.youtube.com/embed/Bv0lMg7RBMI">
          </iframe>
        </div>



      </div>
    </div>
  );
}
