import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCookies } from 'react-cookie';
import { AuthorizationData } from '../../services/storage.service';

interface ApiContextProps {
  get: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  delete: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  patch: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  setUser: (user: AuthorizationData | null, isPersistent?: boolean) => void;
  getUser: () => AuthorizationData | null;
}

const ApiContext = createContext<ApiContextProps | null>(null);

interface ApiProviderProps {
  children: ReactNode;
  baseURL: string;
  initialAuthToken?: string | null;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children, baseURL, initialAuthToken }) => {
  const [userCookie, setUserCookie] = useCookies<'user', AuthorizationData>();
  const instance = axios.create({ baseURL });

  useEffect(() => {
    const createAxiosInstance = async () => {
      try {
        // Add an interceptor to set authorization token before each request
        instance.interceptors.request.use(
          (config) => {
            // Set authorization token if available
            if (userCookie && (userCookie as any)?.user?.token) {
              // TODO: Fix this type issue.
              config.headers['Authorization'] = `Bearer ${(userCookie as any)?.user?.token || ''}`;
            }
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
      } catch (error) {
        // Handle error during instance creation
        console.error('Error creating axios instance:', error);
      }
    };

    createAxiosInstance();
  }, [userCookie]);

  const handleError = (error: AxiosError) => {
    if (error.response) {
      console.error('HTTP error response:', error.response);
      handle403Error(error);
      handle401Error(error);
    } else if (error.request) {
      console.error('HTTP error request:', error.request);
    } else {
      console.error('HTTP error:', error.message);
    }
  }; 
  
  const handle403Error = (error: AxiosError<unknown, any>) => {
    if(error.response?.status === 403) {
      localStorage.clear();
      window.open(`${process.env.REACT_APP_BASE_URL}/request-access`, '__blank');
    } 
  }

  const handle401Error = (error: AxiosError<unknown, any>) => {
    if(error.response?.status === 401) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/signin`;
    } 
  }

  const apiContextValue: ApiContextProps = {
    get: async (url, config) => {
      if (!instance) {
        throw new Error('axiosInstance is not initialized yet');
      }

      try {
        const response: AxiosResponse = await instance.get(url, config);
        return response.data;
      } catch (error: any) {
        handleError(error);
        throw error;
      }
    },
    post: async (url, data, config) => {
      if (!instance) {
        throw new Error('axiosInstance is not initialized yet');
      }

      try {
        const response: AxiosResponse = await instance.post(url, data, config);
        return response.data;
      } catch (error: any) {
        handleError(error);
        throw error;
      }
    },
    put: async (url, data, config) => {
      if (!instance) {
        throw new Error('axiosInstance is not initialized yet');
      }

      try {
        const response: AxiosResponse = await instance.put(url, data, config);
        return response.data;
      } catch (error: any) {
        handleError(error);
        throw error;
      }
    },
    delete: async (url, config) => {
      if (!instance) {
        throw new Error('axiosInstance is not initialized yet');
      }

      try {
        const response: AxiosResponse = await instance.delete(url, config);
        return response.data;
      } catch (error: any) {
        handleError(error);
        throw error;
      }
    },
    patch: async (url, data, config) => {
      if (!instance) {
        throw new Error('axiosInstance is not initialized yet');
      }

      try {
        const response: AxiosResponse = await instance.patch(url, data, config);
        return response.data;
      } catch (error: any) {
        handleError(error);
        throw error;
      }
    },
    setUser: (user: AuthorizationData | null, isPersistent = false) => {
      if(isPersistent) {
        var currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + 3);
        setUserCookie("user", user, { expires: currentDate });
      } else {
        setUserCookie("user", user);
      }
    },
    getUser: () => {
      return (userCookie as any)?.["user"];
    },
  };

  return <ApiContext.Provider value={apiContextValue}>{children}</ApiContext.Provider>;
};

export const useApi = (): ApiContextProps => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};
